module.exports = {
  getCurrentDate: () => {
    const d = new Date();
    let month = (d.getMonth() + 1).toString();
    if (month.length < 2) {
      month = `0${month}`;
    }
    let day = d.getDate().toString();
    if (day.length < 2) {
      day = `0${day}`;
    }
    return `${d.getFullYear()}-${month}-${day}`;
  },
};
